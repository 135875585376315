/*!
 * Bootstrap Reboot v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
*,
*::before,
*::after {
	box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}

body {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	margin: 0;
	// color: #212529;
	background-color: #fff;
	font-weight: 300;
	// font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
	// 	sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	font-size: 1rem;
	line-height: 1.5;
}

hr {
	margin: 1rem 0;
	border: 0;
	background-color: currentColor;
	color: inherit;
	// 2021-09-07 Remove default opacity for hr
	// opacity: 0.25;
}

h6,
h5,
h4,
h3,
h2,
h1 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 600;
	line-height: 1.2;
}

h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	h1 {
		font-size: 2.5rem;
	}
}

h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	h2 {
		font-size: 2rem;
	}
}

h3 {
	font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
	h3 {
		font-size: 1.75rem;
	}
}

h4 {
	font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
	h4 {
		font-size: 1.5rem;
	}
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
	cursor: help;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul {
	padding-left: 2rem;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 600;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 0.875em;
}

mark {
	background-color: #fcf8e3;
	padding: 0.2em;
}

sub,
sup {
	position: relative;
	vertical-align: baseline;
	font-size: 0.75em;
	line-height: 0;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: #0d6efd;
	text-decoration: underline;
}
a:hover {
	color: #0a58ca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-size: 1em;
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
	direction: ltr /* rtl:ignore */;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	font-size: 0.875em;
}
pre code {
	color: inherit;
	font-size: inherit;
}

code {
	word-wrap: break-word;
	color: #d63384;
	font-size: 0.875em;
	white-space: pre-wrap;
	word-break: break-all;
}
a > code {
	color: inherit;
}

kbd {
	border-radius: 0.2rem;
	background-color: #212529;
	padding: 0.2rem 0.4rem;
	color: #fff;
	font-size: 0.875em;
}
kbd kbd {
	padding: 0;
	font-weight: 600;
	font-size: 1em;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	border-collapse: collapse;
	caption-side: bottom;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: #6c757d;
	text-align: left;
}

th {
	text-align: inherit;
	text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
	border-width: 0;
	border-style: solid;
	border-color: inherit;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
}

button,
select {
	text-transform: none;
}

[role='button'] {
	cursor: pointer;
}

select {
	word-wrap: normal;
}
select:disabled {
	opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
	cursor: pointer;
}

::-moz-focus-inner {
	border-style: none;
	padding: 0;
}

textarea {
	resize: vertical;
}

fieldset {
	margin: 0;
	border: 0;
	padding: 0;
	min-width: 0;
}

legend {
	float: left;
	margin-bottom: 0.5rem;
	padding: 0;
	width: 100%;
	font-size: calc(1.275rem + 0.3vw);
	line-height: inherit;
}
@media (min-width: 1200px) {
	legend {
		font-size: 1.5rem;
	}
}
legend + * {
	clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

[type='search'] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::file-selector-button {
	font: inherit;
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
}

output {
	display: inline-block;
}

iframe {
	border: 0;
}

summary {
	display: list-item;
	cursor: pointer;
}

progress {
	vertical-align: baseline;
}

[hidden] {
	display: none !important;
}
