@font-face {
	font-style: normal;
	font-weight: 300;
	src: url('/fonts/exo-2-v20-latin-300.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: italic;
	font-weight: 300;
	src: url('/fonts/exo-2-v20-latin-300italic.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 500;
	src: url('/fonts/exo-2-v20-latin-500.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: italic;
	font-weight: 500;
	src: url('/fonts/exo-2-v20-latin-500italic.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/exo-2-v20-latin-600.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: italic;
	font-weight: 600;
	src: url('/fonts/exo-2-v20-latin-600italic.woff2') format('woff2');
	font-family: 'Exo 2';
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 800;
	src: url('/fonts/HeavitasNeue-ExtraBold.otf') format('opentype');
	font-family: 'Heavitas Neue';
	font-display: swap;
}

@font-face {
	font-style: normal;
	font-weight: 600;
	src: url('/fonts/HeavitasNeue-Bold.otf') format('opentype');
	font-family: 'Heavitas Neue';
	font-display: swap;
}
