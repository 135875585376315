// sass-lint:disable no-mixed-declarations

@use 'functions';
@use 'variables';
@use 'colors';
@use 'animations';

:root {
	// Stack spacing adds a margin to the bottom of elements.
	--space-stack-3xs: 0.125rem; // 2px;
	--space-stack-2xs: 0.25rem; // 4px
	--space-stack-xs: 0.5rem; // 8px
	--space-stack-sm: 0.75rem; // 12px
	--space-stack-md: 1rem; // 16px
	--space-stack-lg: 1.5rem; // 24px;
	--space-stack-xl: 2rem; // 32px;
	--space-stack-2xl: 3rem; // 48px;
	--space-stack-3xl: 4rem; // 64px;

	// Inline spacing = right margins to elements.
	--space-inline-3xs: 0.125rem; // 2px;
	--space-inline-2xs: 0.25rem; // 4px
	--space-inline-xs: 0.5rem; // 8px
	--space-inline-sm: 0.75rem; // 12px
	--space-inline-md: 1rem; // 16px
	--space-inline-lg: 1.5rem; // 24px;
	--space-inline-xl: 2rem; // 32px;
	--space-inline-2xl: 3rem; // 48px;
	--space-inline-3xl: 4rem; // 64px;

	// Inset spacing used when equal padding to all four sides of an element.
	--space-inset-none: 0;
	--space-inset-3xs: 0.125rem; // 2px;
	--space-inset-2xs: 0.25rem; // 4px
	--space-inset-xs: 0.5rem; // 8px
	--space-inset-sm: 0.75rem; // 12px
	--space-inset-md: 1rem; // 16px
	--space-inset-lg: 1.5rem; // 24px;
	--space-inset-xl: 2rem; // 32px;
	--space-inset-2xl: 3rem; // 48px;
	--space-inset-3xl: 4rem; // 64px;
	--space-inset-4xl: 6rem; // 96px
	--space-inset-5xl: 8rem; // 128px
	--space-inset-6xl: 10rem; // 160px
	--space-inset-7xl: 12rem; // 192px

	// Border radiuses
	--border-radius-025: 0.25rem; // 4px
	--border-radius-05: 0.5rem; // 8px
	--border-radius-1: 1rem; // 16px
	--border-radius-1-5: 1.5rem; // 24px
	--border-radius-2: 2rem; // 32px
	--border-radius-3: 3rem; // 48px
	--border-radius-3-5: 3.5rem; // 56px

	--border-radius-card: 1.5rem;
	--border-radius-button: 1.75rem; // 28px
	--border-radius-input: 0.5rem; // 24px
	--border-radius-menu: 2.75rem; // 44px
	--border-radius-round: 50%;

	// Misc
	--z-index-0: -1;
	--z-index-1: 100;
	--z-index-2: 200;
	--z-index-3: 300;
	--z-index-4: 400;
	--z-index-5: 500;
	--z-index-6: 600;
	--z-index-7: 700;
	--z-index-8: 800;
	--z-index-9: 900;
	--z-index-10: 1000;
	--z-index-max: 9999;

	--z-index-breadcrumbs: 199;

	// Font family
	--font-family-heavitas:
		'Heavitas Neue', 'Exo 2', system-ui, -apple-system, 'Segoe UI', roboto, 'Helvetica Neue', arial, 'Noto Sans',
		'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

	// Font weights
	--font-weight-light: 300;
	--font-weight-bold: 600;

	--font-weight-heavitas-bold: 600;
	--font-weight-heavitas-extra-bold: 800;

	// Font-sizes
	--font-size-h1: clamp(2.5rem, 5vw, 4.1875rem);
	--font-size-h2: clamp(2rem, 2.75vw, 3.125rem);
	--font-size-h3: clamp(1.75rem, 2vw, 2.375rem);
	--font-size-h4: clamp(1.5rem, 1.5vw, 1.75rem);
	--font-size-h5: 1.25rem;
	--font-size-h6: 1rem;
	--font-size-small: 0.875rem;

	// Heavitas Neue Font-sizes
	--heavitas-font-size-h1: clamp(2.25rem, 2.75vw, 3.5rem);
	--heavitas-font-size-h2: clamp(1.75rem, 2.5vw, 3rem);
	--heavitas-font-size-h3: clamp(1.5625rem, 2vw, 1.55rem);

	// Header font-sizes
	--header-font-size-h1: clamp(2.5rem, 5vw, 3.5rem);
	--header-font-size-h5: 1.15rem; // 16px
	--header-font-size-ingress: 1.15rem; // 16px

	// Link hover effect - used in LinkButton text variant and in MarkLink
	--link-hover-effect-color: var(--primary-yellow);

	--global-menu-height: 5rem;
	--global-menu-height-new: 4.75rem; // TODO: Can replace --global-menu-height when we have switched menu

	@media (max-width: variables.$breakpoint-md) {
		--global-menu-height: 4.25rem;
	}

	@media (max-width: variables.$menu-bp) {
		--global-menu-height-new: 3.5rem;
	}

	--global-info-banner-height: 4rem;

	// Colors
	--black-60-alpha: rgba(0, 0, 0, 0.6);
	--black-50-alpha: rgba(0, 0, 0, 0.5);
	--black-30-alpha: rgba(0, 0, 0, 0.3);
	--black-16-alpha: rgba(0, 0, 0, 0.16);
	--black-6-alpha: rgba(0, 0, 0, 0.06);

	--global-menu-panel-width: 30rem;

	--article-component-margin: 2rem 0;

	// Text max-width
	--text-max-width: 57rem;

	@media (max-width: variables.$breakpoint-lg) {
		--text-max-width: 35rem;
	}

	@media (max-width: variables.$breakpoint-md) {
		--text-max-width: 100%;
	}

	// Text max-width if sub menu is on page
	--text-max-width-sub-menu: 55rem;

	// Image heights
	--image-min-height-md: 16rem; // Deprecated use --image-height instead
	--image-min-height-sm: 13rem; // Deprecated use --image-height instead

	--image-height: 16rem;

	--global-max-width: 2560px;
}

// sass-lint:enable no-mixed-declarations
