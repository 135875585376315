// sass-lint:disable no-mixed-declarations

@use 'bootstrap-reboot';
@use 'styles';
@import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
@import '@fortawesome/fontawesome-pro/css/light.css';

html.normal-scroll {
	scroll-behavior: auto !important;
}

body {
	background-color: var(--bg-white);
	color: var(--body-color);
	font-size: 16px;
	line-height: 1.6;
	font-family:
		'Exo 2',
		system-ui,
		-apple-system,
		'Segoe UI',
		Roboto,
		'Helvetica Neue',
		Arial,
		'Noto Sans',
		'Liberation Sans',
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji',
		'Segoe UI Symbol',
		'Noto Color Emoji';
	letter-spacing: 0.009rem;

	@media (min-width: 2560px) {
		background-color: rgba(222, 255, 222, 0.15);
	}
}

main {
	background-color: var(--bg-white);
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

a {
	color: var(--primary-green);
	font-weight: var(--font-weight-bold);
	text-decoration: none;

	&:hover {
		color: var(--text-heading-color);
	}

	u {
		text-decoration: none;
	}
}

.fcWidget:not(.fc-open) {
	z-index: calc(var(--z-index-10) - 1) !important;
}

.fcWidget.fc-open {
	z-index: var(--z-index-max) !important;
}
